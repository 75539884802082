#input-error-otp-code {
    margin: 0 auto;
    padding: .5rem;
    place-content: center;
    margin-bottom: 1rem;
}

.list-view-pf-stacked + .list-view-pf-stacked {
    margin-top: 10px;
}

.list-view-pf-stacked {
    background-color: #e0e2e5;
    padding: 1rem;
}

.list-view-pf-stacked:hover {
    background-color: #c1c7cc;
    cursor: grab;
}

.list-group-item-heading {
    font-family: BoschSansMedium;
    font-size: 20px;
}

.kc-recovery-codes-actions {
    margin: 1.5rem 0 2rem 0;
}

.kc-recovery-codes-actions > a {
    cursor: grab;
}

.kc-recovery-codes-list > li {
    padding-left: 0;
}

.kc-recovery-codes-actions > a:not(:first-child) {
    margin-left: 3rem;
}

#saveRecoveryAuthnCodesBtn {
    margin-top: 3rem;
}
